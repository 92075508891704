'use client';

import useBroadcastChannel from '@/hooks/use-broadcast-channel';
import useChanged from '@/hooks/use-changed';
import { getUser } from '@/lib/auth';
import { isError } from '@/lib/helpers';
import {
  BibleBooks,
  Content,
  MY_SERMONS_SEARCH_TYPE,
  MY_SERMONS_SIDEBAR,
  SEARCH_SUB_TYPE,
  SEARCH_TYPE,
  TbTocType,
  User,
  UserPayload,
  Week,
} from '@/types/sermons';
import Cookies from 'js-cookie';
import { decode, JwtPayload } from 'jsonwebtoken';
import { ReadonlyURLSearchParams, useRouter } from 'next/navigation';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { PREP_BAR_TAB } from '../molecules/sermon-prep-bar';

export interface AppState {
  user: User | null;
  token: string | null;

  bookmarks: string[] | null;

  sermonModalOpen: boolean;

  searchResults: {
    ids: string[];
    resultsTerm: string | null;
  };

  mySermons: {
    sidebar: MY_SERMONS_SIDEBAR;
    isMySermons: boolean;
    pericope: string;
  };

  couponOpen: boolean;

  contentTypes: TbTocType[] | null;
  mySermonsContentTypes: TbTocType[] | null;

  editExplanationOpen: boolean;

  sermonPrepTab: PREP_BAR_TAB;

  sermon: Content | null;
  isSermonLoading: boolean;

  searchParams: ReadonlyURLSearchParams;
  searchParamsReady: boolean;

  bibleBooks: BibleBooks | null;

  currentWeek: Week | null;

  searchType: SEARCH_TYPE | MY_SERMONS_SEARCH_TYPE;
  searchSubType: SEARCH_SUB_TYPE;
  searchTerm: string;
  searchOldTestamentBook: number | '';
  searchNewTestamentBook: number | '';
  searchChapter: number | '';
  searchPericope: string;

  isLoading: boolean;

  isBrowser: boolean;

  isInit: boolean;
}

export const initialState: AppState = {
  user: null,
  token: null,

  bookmarks: null,

  sermonModalOpen: false,

  searchResults: {
    ids: [],
    resultsTerm: null,
  },

  mySermons: {
    isMySermons: false,
    pericope: 'GEN001',
    sidebar: 'none',
  },

  couponOpen: false,

  contentTypes: null,
  mySermonsContentTypes: null,

  editExplanationOpen: false,

  sermonPrepTab: 'content',

  sermon: null,
  isSermonLoading: true,

  searchParams: new ReadonlyURLSearchParams(),
  searchParamsReady: false,

  bibleBooks: null,

  currentWeek: null,

  searchType: 'Sermon Prep',
  searchSubType: 'Keyword',
  searchTerm: '',
  searchOldTestamentBook: '',
  searchNewTestamentBook: '',
  searchChapter: '',
  searchPericope: '',

  isLoading: true,

  isBrowser: false,

  isInit: true,
};

type AppStateKeys = keyof AppState;

type ActionMap = {
  [K in AppStateKeys]: {
    type: K;
    payload: AppState[K];
  };
};

type Action = ActionMap[keyof ActionMap];

const stateContext = createContext<
  { state: AppState; dispatch: React.Dispatch<Action> } | undefined
>(undefined);

const stateReducer = (state: AppState, action: Action): AppState => ({
  ...state,
  [action.type]: action.payload,
});

const initializeUser = async (
  dispatch: React.Dispatch<Action>,
  token: string,
) => {
  const newUser = await getUser(token).catch(() => null);
  if (newUser === null || isError(newUser) || !newUser.loggedIn) {
    Cookies.remove('token');
    localStorage.removeItem('token');
    dispatch({ type: 'token', payload: null });
    dispatch({ type: 'user', payload: null });
    return;
  }
  const userPayload = decode(token) as UserPayload & JwtPayload;
  Cookies.set('token', token, {
    expires: new Date(userPayload.exp * 1000),
    path: '/',
  });
  localStorage.setItem('token', token);
  localStorage.removeItem('signup');
  dispatch({ type: 'token', payload: token });
  dispatch({ type: 'user', payload: newUser });
};

export const useInitializeUser = (dispatch: React.Dispatch<Action>) => {
  const isRunningRef = useRef(false);
  const lastTokenRef = useRef<string | null>(null);
  const queuedTokenRef = useRef<string | null>(null);

  const initializeUserWithQueue = useCallback(
    async (token: string) => {
      if (isRunningRef.current) {
        // Queue the latest token
        queuedTokenRef.current = token;
        return;
      }

      isRunningRef.current = true;

      do {
        const currentToken = queuedTokenRef.current || token;

        // Skip if the token is the same as the last processed token
        if (currentToken === lastTokenRef.current) {
          isRunningRef.current = false;
          return;
        }

        lastTokenRef.current = currentToken;
        queuedTokenRef.current = null;

        // Initialize the user
        await initializeUser(dispatch, currentToken);
      } while (queuedTokenRef.current);

      isRunningRef.current = false;
    },
    [dispatch],
  );

  return initializeUserWithQueue;
};

const loadStateFromSessionStorage = (key = 'appState'): Partial<AppState> => {
  try {
    const storedState = sessionStorage.getItem(key);
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      if (parsedState.searchParams) {
        parsedState.searchParams = new ReadonlyURLSearchParams(
          parsedState.searchParams,
        );
      }
      return parsedState;
    }
    return {};
  } catch (error) {
    console.error('Failed to load state from sessionStorage:', error);
    return {};
  }
};

const saveStateToSessionStorage = (state: AppState, key = 'appState') => {
  try {
    const stateToSave = {
      ...state,
      searchParams: Object.fromEntries(state.searchParams.entries()),
    };
    sessionStorage.setItem(key, JSON.stringify(stateToSave));
  } catch (error) {
    console.error('Failed to save state to sessionStorage:', error);
  }
};

export const StateProvider = ({
  children,
  initialState: parentState = {},
}: PropsWithChildren<{ initialState?: Partial<AppState> }>) => {
  const innerState = useRef<AppState>({
    ...initialState,
    ...parentState,
  });

  const [state, dispatch] = useReducer(stateReducer, innerState.current);

  const initializeUserWithQueue = useInitializeUser(dispatch);

  const broadcastToken = useBroadcastChannel<string>(
    'auth',
    'token',
    (token) => token && initializeUserWithQueue(token),
  );

  useEffect(() => {
    saveStateToSessionStorage(innerState.current, 'initialState');

    const hasStoredToken = !!(
      (typeof window !== 'undefined' && Cookies.get('token')) ||
      localStorage.getItem('token')
    );

    const savedState = loadStateFromSessionStorage();
    Object.entries(savedState)
      .filter(
        ([key]) =>
          !['isBrowser', 'isLoading'].includes(key) &&
          ((hasStoredToken && key !== 'token' && key !== 'user') ||
            !hasStoredToken),
      )
      .forEach(([key, value]) => {
        dispatch({ type: key, payload: value } as Action);
      });
    dispatch({ type: 'isBrowser', payload: true });
    dispatch({ type: 'isInit', payload: false });
  }, []);

  const token =
    state.token ||
    (typeof window !== 'undefined'
      ? Cookies.get('token') || localStorage.getItem('token')
      : null);
  const tokenChanged = useChanged(token);
  const userChanged = useChanged(state.user);

  useEffect(() => {
    if (tokenChanged || state.token !== token) {
      if (token) {
        broadcastToken(token);
        initializeUserWithQueue(token);
      }
    } else {
      dispatch({ type: 'isLoading', payload: false });
    }
  }, [state.token, token, tokenChanged]);

  useEffect(() => {
    if (userChanged && state.user) {
      dispatch({ type: 'isLoading', payload: false });
    }
  }, [state.user, userChanged]);

  useEffect(() => {
    if (state.isBrowser) {
      saveStateToSessionStorage(state);
    }
  }, [state]);

  return (
    <stateContext.Provider value={{ state, dispatch }}>
      {children}
    </stateContext.Provider>
  );
};

export const useAppState = () => {
  const context = useContext(stateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a StateProvider');
  }
  return context;
};

export const useResetAppState = () => {
  const router = useRouter();
  const { dispatch } = useAppState();
  const [loading, setLoading] = useState(false);

  const resetState = (logout = true) => {
    if (logout) {
      localStorage.removeItem('token');
      Cookies.remove('token');
    }
    sessionStorage.removeItem('appState');
    const initialState = loadStateFromSessionStorage('initialState');
    Object.entries(initialState)
      .filter(
        ([key]) =>
          !['isBrowser', 'isInit', 'isLoading'].includes(key) &&
          (logout || (!logout && key !== 'token' && key !== 'user')),
      )
      .forEach(([key, value]) => {
        dispatch({ type: key, payload: value } as Action);
      });
    dispatch({ type: 'isBrowser', payload: true });
    dispatch({ type: 'isInit', payload: true });
  };

  const broadcastReset = useBroadcastChannel<boolean>(
    'state',
    'reset',
    (logout) => resetState(logout),
  );

  useEffect(() => {
    if (loading) {
      dispatch({ type: 'isInit', payload: false });
      setLoading(false);
      router.push('/');
    }

    return () => setLoading(false);
  }, [loading, dispatch]);

  return (logout = true) => {
    setLoading(true);

    resetState(logout);
    broadcastReset(logout);
  };
};
