'use client';

import useUpdatedUrl from '@/hooks/use-update-url';
import { Content } from '@/types/sermons';
import classNames from 'classnames';
import Link from 'next/link';
import { useEffect } from 'react';
import { useAppState } from '../providers/state-provider';

export const PREP_BAR_TABS = [
  'content',
  'tools',
  'bible',
  'illustrations',
  'news',
] as const;
export type PREP_BAR_TAB = (typeof PREP_BAR_TABS)[number];

const isPrepTab = (tab?: string | null): tab is PREP_BAR_TAB => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return PREP_BAR_TABS.includes(tab as any);
};

export default function SermonPrepBar({
  className,
  sermon,
}: {
  className?: string;
  sermon: Content;
}) {
  const {
    state: { sermonPrepTab, searchParams },
    dispatch,
  } = useAppState();

  const updatedUrl = useUpdatedUrl();

  const pericopeCode = searchParams.get('pericopeCode');

  const pericope = sermon?.pericopes?.filter(
    (p) => !pericopeCode || p.number === pericopeCode,
  )?.[0];

  const tab = searchParams.get('tab');

  useEffect(() => {
    dispatch({
      type: 'sermonPrepTab',
      payload: isPrepTab(tab) ? tab : 'content',
    });

    return () => dispatch({ type: 'sermonPrepTab', payload: 'content' });
  }, [tab]);

  return (
    <nav
      className={classNames(
        'h-10 w-full overflow-auto bg-sermons-dark font-bold text-white',
        className,
      )}
    >
      <div className='grid min-w-[672px] grid-cols-5 whitespace-nowrap'>
        <Link
          className={classNames(
            'flex h-10 cursor-pointer items-center justify-center border-b-4',
            {
              'border-b-sermons-highlight': sermonPrepTab === 'content',
              'border-b-sermons-dark': sermonPrepTab !== 'content',
            },
          )}
          onClick={() =>
            dispatch({ type: 'sermonPrepTab', payload: 'content' })
          }
          href={updatedUrl({ tab: null })}
        >
          <div>{sermon?.tbTocType ? sermon.tbTocType.tocName : 'Content'}</div>
        </Link>
        {sermon?.studyTools &&
          [1, 8, 10, 15, 16, 17, 19, 24, 25, 28, 29, 35].includes(
            +sermon.contentType,
          ) && (
            <Link
              className={classNames(
                'flex h-10 cursor-pointer items-center justify-center border-b-4',
                {
                  'border-b-sermons-highlight': sermonPrepTab === 'tools',
                  'border-b-sermons-dark': sermonPrepTab !== 'tools',
                },
              )}
              onClick={() =>
                dispatch({ type: 'sermonPrepTab', payload: 'tools' })
              }
              href={updatedUrl({ tab: 'tools' })}
            >
              <div>Prep</div>
            </Link>
          )}
        {pericope && (
          <Link
            className={classNames(
              'flex h-10 cursor-pointer items-center justify-center border-b-4',
              {
                'border-b-sermons-highlight': sermonPrepTab === 'bible',
                'border-b-sermons-dark': sermonPrepTab !== 'bible',
              },
            )}
            onClick={() =>
              dispatch({ type: 'sermonPrepTab', payload: 'bible' })
            }
            href={updatedUrl({ tab: 'bible' })}
          >
            <div>Bible</div>
          </Link>
        )}
        {sermon &&
          [1, 8, 10, 15, 16, 17, 19, 24, 25, 28, 29, 35].includes(
            +sermon.contentType,
          ) &&
          pericope && (
            <>
              <Link
                className={classNames(
                  'flex h-10 cursor-pointer items-center justify-center border-b-4',
                  {
                    'border-b-sermons-highlight':
                      sermonPrepTab === 'illustrations',
                    'border-b-sermons-dark': sermonPrepTab !== 'illustrations',
                  },
                )}
                onClick={() =>
                  dispatch({ type: 'sermonPrepTab', payload: 'illustrations' })
                }
                href={updatedUrl({ tab: 'illustrations' })}
              >
                <div>Illustrations</div>
              </Link>
              <Link
                className={classNames(
                  'flex h-10 cursor-pointer items-center justify-center border-b-4',
                  {
                    'border-b-sermons-highlight': sermonPrepTab === 'news',
                    'border-b-sermons-dark': sermonPrepTab !== 'news',
                  },
                )}
                onClick={() =>
                  dispatch({ type: 'sermonPrepTab', payload: 'news' })
                }
                href={updatedUrl({ tab: 'news' })}
              >
                <div>Current Events</div>
              </Link>
            </>
          )}
      </div>
    </nav>
  );
}
