
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"2a1041b8b0c4a5b9ead69ad08b26f485762a2cff":"$$ACTION_0","4c869df5791fdf9942ac0d5400bc0a6f4c4d1ff0":"revalidateTag","5a81f5459961a365465b4f896bf3829dc839cdcf":"logRequest","6f0e2f319b34fab24f2cacd405f1600a8eec1d6c":"$$ACTION_2","8b7c9025b499ee3c8d172525da6f6862aa629d09":"convertToHtml","99d978d9b08c88e4c8318347cdcfe2be9a4c3a09":"revalidate","c09b0a7ed64c597a200eb67034b1d795059e2b5c":"$$ACTION_3","c831cd46a1e195bf4d1ca13f5acd3e2b23e44b6f":"$$ACTION_1","d02ef1f4a1105c762a06358563235a00bf1429df":"$$ACTION_4","f0fa4b7ab8fe2fe93e5605e0715d27abdc2dbdac":"revalidateThisWeek"} */ export var convertToHtml = createServerReference("8b7c9025b499ee3c8d172525da6f6862aa629d09");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var revalidateThisWeek = createServerReference("f0fa4b7ab8fe2fe93e5605e0715d27abdc2dbdac");
export var revalidate = createServerReference("99d978d9b08c88e4c8318347cdcfe2be9a4c3a09");
export var revalidateTag = createServerReference("4c869df5791fdf9942ac0d5400bc0a6f4c4d1ff0");
export var logRequest = createServerReference("5a81f5459961a365465b4f896bf3829dc839cdcf");

