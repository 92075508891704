import { SuccessResponse } from '@/components/molecules/my-account';
import { BIBLE_TRANSLATION, BibleBook } from '@/lib/helpers';

export interface PericopeWithVerses extends Pericope {
  verses: Verse[];
}

export interface ExtraData {
  priority?: number | null;
  type?: string;
}

interface DisplayedScripture {
  id?: number;
  sermonId?: string;
  displayedScripture: string;
  needsVerification?: boolean;
}

export interface ContentUpdateDto {
  id?: string;
  contentTitle: string;
  author: string;
  contentType: string;
  content: string;
  publicationTitle: string;
  publisher: string;
  yearPublished: string;
  hidden: boolean;
  priority: number | null;
  displayedScripture: string | null;
  pericopes: string[];
  userId?: number;
  isDraft?: boolean;
}

export interface ContentDto {
  userId?: number;
  contentTitle: string;
  author: string;
  contentType: string;
  content: string;
  truncatedContent?: string;
  publicationTitle?: string;
  publisher?: string;
  yearPublished?: string;
  hidden?: boolean;
  priority?: number;
  displayedScripture?: string;
  pericopes: string[];
  notes?: string;
  isDraft?: boolean;
  studyToolsId?: number;
}

export interface BaseContent {
  id: string;
  contentTitle: string;
  slug: string | null;
  pericopes?: PericopeWithVerses[];
  displayedScripture?: DisplayedScripture[];
}

export interface Content extends BaseContent {
  hidden: boolean;
  userId?: string;
  // lastUpdated: string;
  isbn: string;
  publicationTitle: string;
  // publicationAuthor?: string;
  publisher: string;
  yearPublished: string;
  // keywords?: string;
  content: string;
  truncatedContent: string | null;
  contentType: string;
  // powerPointFiles?: string;
  // ppsNumber?: string;
  // igniterMediaId?: string;
  // releaseDate?: string;
  // createdDate: string;
  // updatedDate?: string;
  // seriestitle?: string;
  // seriesOrder?: number;
  // facebookImage?: string;
  extraData?: ExtraData;
  // sermonTitle?: string;
  // sermonAuthor?: string;
  // sermonImage?: string;
  // deletedDate?: string;
  authors?: Author[];
  tbTocType?: TbTocType;
  studyTools?: StudyTools;
  rss?: Rss;
  // uuid?: string;
  // truncated?: boolean;
  isDraft: boolean;
}

export interface ContentResponse {
  contents: ContentWithSnippet[];
  lastPage: number;
  total: number;
}

export interface SermonsToSeries {
  id: number;
  position: number;
  seriesId: number;
  sermonId: number;
}

export interface SermonSeries {
  id: number;
  title: string;
  author: string;
  position: number;
  active: boolean;
  sermons: BaseContent[];
  sermonsToSeries: SermonsToSeries[];
}

export interface SeriesCategory {
  id: number;
  title: string;
  linkTitle: string;
  headerColor: string;
  textColor: string;
  position: number;
  onThisWeek: boolean;
  active: boolean;
  forceShow: number;
  masterCategoryId: number;
  created: string;
  updated?: string;
  series: SermonSeries[];
}

export interface SeriesCategoriesResponse {
  seriesCategories: SeriesCategory[];
  total: number;
  lastPage: number;
}

export const UserStatusArray = ['Logged In', 'Not Logged In', 'Both'] as const;
export type UserStatus = (typeof UserStatusArray)[number];
export const ShowOnArray = ['Front Page', 'Everywhere'] as const;
export type ShowOn = (typeof ShowOnArray)[number];

export interface Announcement {
  id: number;
  announcement: string;
  start: string;
  end: string;
  userStatus: UserStatus;
  showOn: ShowOn;
}

export interface AnnouncementsResponse {
  announcements: Announcement[];
}

interface BatchEditSession {
  id: string;
  sessionName: string;
  touchedIds?: string;
  currentId?: string;
  dateCreated: string;
  dateUpdated?: string;
  dateDeleted?: string;
  locked: number;
  contentType: string;
  totalUntouched: number;
  totalTouched: number;
  totalSkipped: number;
  totalDeleted: number;
  totalNeedsVerification: number;
}

export interface BatchEditResponse {
  sessions: BatchEditSession[];
}

export interface Coupon {
  id: string;
  code: string;
  isTrial: boolean;
  discount: string;
  months: string;
  hasSermons: boolean;
  hasBulletins: boolean;
  hasChildrens: boolean;
  hasPpt: boolean;
  hasMotion: boolean;
  expires?: string;
}

export interface CouponsResponse {
  coupons: Coupon[];
}

export interface PendingCancellation {
  id: number;
  userId: number;
  subscription: string;
}

export interface CustomerPaymentProfile {
  customerType: string;
  billTo: {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
  };
  customerPaymentProfileId: string;
  payment: {
    creditCard: {
      cardNumber: string;
      expirationDate: string;
      cardType: string;
    };
  };
}

export const isUser = (
  users: User | BouncedUser | null | undefined,
): users is User =>
  !!users && Object.hasOwn(users, 'email') && !Object.hasOwn(users, 'step');

export const isUsers = (
  users: User[] | BouncedUser[] | null | undefined,
): users is User[] =>
  !!users && Array.isArray(users) && !!users[0] && isUser(users[0]);

export interface User {
  id: number;
  customerId: number;
  ipAddress: string;
  username: string;
  password: string;
  salt: string;
  email: string;
  activationCode?: string;
  forgottenPasswordCode?: string;
  forgottenPasswordTime?: number;
  rememberCode?: string;
  createdOn: number;
  lastLogin: number;
  active: boolean;
  firstName: string;
  lastName: string;
  company?: string;
  phone?: string;
  addressOne: string;
  addressTwo?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  fax?: string;
  emailTwo?: string;
  emailThree?: string;
  url?: string;
  profilePicture: string;
  denominationId?: number;
  dateCreated: string;
  dateUpdated?: string;
  source?: string;
  isAdmin: boolean;
  hasIllustrations: boolean;
  hasWorshipAids: boolean;
  hasDramas: boolean;
  hasBas: boolean;
  hasBasMagazineUs: boolean;
  hasBasMagazineCa: boolean;
  hasChildrenSermons: boolean;
  hasChildrensBulletinsOne: boolean;
  hasChildrensBulletinsTwo: boolean;
  hasPowerpoints: boolean;
  hasMotionBackgrounds: boolean;
  hasVideos: boolean;
  hasStewardshipcampaign: boolean;
  hasWorshipart: boolean;
  hasLsppt: boolean;
  hasFeaturedSermons: boolean;
  hasMaxiedunnam: boolean;
  isSignupAsburylist: number;
  isSignupMaxiedunnam: number;
  lastTransaction?: string;
  lastRenewal?: string;
  lastCost?: number;
  expirationDate: string;
  statusId: number;
  emailfromClergy: number;
  emailfromMinisters: number;
  emailfromSpiritmail: number;
  defaultSearch?: string;
  notes?: string;
  lectionaryCalendarFavorite: string;
  denomination?: string;
  translation?: BIBLE_TRANSLATION;
  isAutoRenew: boolean;
  years: number;
  hideBookOverlay: number;
  ctcCustomerId?: string;
  hasCtcBulletinsAges3to6: boolean;
  hasCtcBulletinsAges7to12: boolean;
  lastClosedDate?: string;
  rememberToken?: string;
  emailVerifiedAt?: string;
  lastLoginAt?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  customerProfileId?: string;
  customerPaymentProfileId?: string;
  pendingCancellations: PendingCancellation[];
  isRenewal?: boolean;
  isExpired?: boolean;
  customerPaymentProfile?: CustomerPaymentProfile;
  renewalCouponEnabled: boolean;
  loggedIn?: boolean;
}

export const isBouncedUser = (
  user: User | BouncedUser | null | undefined,
): user is BouncedUser =>
  !!user && Object.hasOwn(user, 'email') && Object.hasOwn(user, 'step');

export const isBouncedUsers = (
  users: User[] | BouncedUser[] | null | undefined,
): users is BouncedUser[] =>
  !!users && Array.isArray(users) && !!users[0] && isBouncedUser(users[0]);

export interface BouncedUser {
  step: number | null;
  email: string;
  firstName: string | null;
  lastName: string | null;
  sermonPrep: boolean | null;
  bulletins: boolean | null;
  childrensSermons: boolean | null;
  worshipPrep1: boolean | null;
  worshipPrep2: boolean | null;
  coupon: string | null;
  years: number | null;
  phone: string | null;
  addressOne: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  denominationId: number | null;
  lectionaryCalendarFavorite: string | null;
  translation: string | null;
}

export interface UsersResponse {
  users: User[] | BouncedUser[];
  lastPage: number;
}

export interface CreateResponse extends SuccessResponse {
  id: number | string;
}

export interface ErrorResponse {
  statusCode: number;
  message: string | string[];
}

interface Author {
  id: number;
  firstname: string;
  lastname: string;
  sermons?: Partial<Content>[];
}

export interface AuthorsResponse {
  authors: Author[];
}

export interface BibleKeywordResponse {
  books: BibleBook[];
  pericopeResults: PericopeWithVerses[];
  versePericopeResults: PericopeWithVerses[];
  verseResults: VerseWithPericope[];
  pericopeTotal: number;
  versePericopeTotal: number;
  verseTotal: number;
  lastPage: number;
  lastVersePage: number;
}

export interface BibleResponse {
  verses: Verse[];
  pericope: Pericope;
  nextPericope?: Pericope;
  prevPericope?: Pericope;
}

export interface CtcBulletin {
  id: string;
  origFilename: string;
  filename: string;
  date: string;
  isAvailable: boolean;
  ageGroup: '3TO6' | '7TO12';
  scripture: 'Gospel' | 'Epistle' | 'Old Testament';
  dateCreated: string;
  dateUpdated?: string;
  createdBy: string;
  updatedBy?: string;
}

export interface GgBulletin {
  id: 5645;
  lectionaryId: 945;
  pdf: string;
  image: string;
  ageGroup: 1 | 2;
}

export interface BulletinsResponse {
  ctc: CtcBulletin[];
  gg: GgBulletin[];
  lectionaryWeek: Week;
}

export interface DictionaryKeywordResponse {
  wordResults: NewDictionaryWord[];
  definitionResults: NewDictionaryWord[];
  wordsTotal: number;
  definitionsTotal: number;
  lastPage: number;
}

export interface DictionaryScriptureResponse {
  results: NewDictionaryWord[];
  total: number;
  lastPage: number;
}

export interface DictionaryWordsResponse {
  dictionaryWords: NewDictionaryWord[];
}

export interface IllustrationsResponse {
  initialism: string;
  scripture: string;
  illustrationsContent: string;
  illustrationsContentTruncated: string;
  sunday: string;
  truncated: boolean;
}

export interface KingDuncanSermon {
  id: number;
  sermonId: number;
  lectionaryWeek: string;
  date: string;
  dateCreated: string;
  dateUpdated?: string;
  sermon: Content;
  uuid?: string;
}

export interface KingDuncanQuarter {
  id: number;
  year: number;
  quarter: number;
  active: boolean;
}

export interface KingDuncanSermonsResponse {
  kingDuncanSermons: KingDuncanSermon[];
}

export interface ContentWithSnippet extends Content {
  snippet: string;
}

export type MediaType = 'still' | 'motion';

export type ProductType =
  | 'Worship Backgrounds'
  | 'Title Graphics'
  | 'Ultra-Wides'
  | 'Motions'
  | 'Countdowns';

export interface PreviewContent {
  id: string;
  productId: string;
  filename: string;
  filetype: string;
  resolution: string;
  typeName: string;
  roleInSet?: string;
  watermarked: number;
  updatedAt: string;
  asset: string;
}

export interface DownloadContent {
  id: string;
  productId: string;
  filename: string;
  description?: string;
  filetype: string;
  contentType?: string;
  resolution: string;
  updatedAt: string;
  downloadLink: string;
}

export interface MediaContent {
  id: string;
  name: string;
  description?: string;
  releaseDate: string;
  seriesName: string;
  subTitle: string;
  isoLanguage: string;
  isVideo: 0;
  updatedAt?: string;
  scriptureReference?: string;
  mediaType: MediaType;
  productType: ProductType;
  gracewayPreviewContents: PreviewContent[];
  gracewayDownloadContents?: DownloadContent[];
}

export interface IllustrationsSubtotals {
  illustrationsTotal: number;
  quotesTotal: number;
  humorTotal: number;
}

export interface SermonAidsSubtotals {
  sermonAidsTotal: number;
  bulletinAidsTotal: number;
}

export interface SearchResultsBase {
  term: string;
  results?: ContentWithSnippet[];
  mediaResults?: MediaContent[];
  lastPage: number;
  snippets: string[];
  total: number;
  subTotals: BibleSectionSubtotals &
    IllustrationsSubtotals &
    SermonAidsSubtotals;
  allIds: string[];
  type: string;
  resultType: string;
  synonyms?: string[];
  antonyms?: string[];
}
export interface SearchResults extends SearchResultsBase {
  type: 'keyword';
}

export interface ScriptureSearchResults extends SearchResultsBase {
  type: 'bible';
  pericope: string;
}

export interface BibleSectionSubtotals {
  wb: number;
  tg: number;
  uw: number;
  mn: number;
  cn: number;
}

export interface MediaLectionarySubtotals {
  ot: BibleSectionSubtotals;
  ps: BibleSectionSubtotals;
  ep: BibleSectionSubtotals;
  gs: BibleSectionSubtotals;
}

interface IllustrationsLectionarySubtotals {
  ot: IllustrationsSubtotals & SermonAidsSubtotals;
  ps: IllustrationsSubtotals & SermonAidsSubtotals;
  ep: IllustrationsSubtotals & SermonAidsSubtotals;
  gs: IllustrationsSubtotals & SermonAidsSubtotals;
}

export interface LectionarySearchResults {
  term: string;
  otCodes: string[];
  psCodes: string[];
  epCodes: string[];
  gsCodes: string[];
  otResults?: ContentWithSnippet[];
  psResults?: ContentWithSnippet[];
  epResults?: ContentWithSnippet[];
  gsResults?: ContentWithSnippet[];
  kwResults?: SearchResults;
  mediaResults?: MediaContent[];
  lastPage?: number;
  otLastPage?: number;
  psLastPage?: number;
  epLastPage?: number;
  gsLastPage?: number;
  total?: number;
  otTotal?: number;
  psTotal?: number;
  epTotal?: number;
  gsTotal?: number;
  subTotals?: MediaLectionarySubtotals & IllustrationsLectionarySubtotals;
  allIds?: string[];
  allOtIds?: string[];
  allPsIds?: string[];
  allEpIds?: string[];
  allGsIds?: string[];
  type: string;
  resultType: string;
}

interface SeriesCategories {
  id: number;
  title: string;
  linkTitle: string;
  headerColor: string;
  textColor: string;
  position: number;
  onThisWeek: number;
  active: number;
  forceShow: number;
  masterCategoryId: number;
  created: string;
  updated?: string;
}

export interface TrendingSocial {
  id: number;
  title: string;
  url: string;
  month: string;
  dateCreated: string;
}

export interface Week {
  id: number;
  number: string;
  cycle: 'A' | 'B' | 'C';
  denomination: string;
  lectionary: string;
  abbreviation: string;
  ot: string;
  ot2?: string;
  ps: string;
  ps2?: string;
  ep: string;
  ep2?: string;
  gs: string;
  gs2?: string;
  otSplit: string[];
  ot2Split: string[];
  psSplit: string[];
  ps2Split: string[];
  epSplit: string[];
  ep2Split: string[];
  gsSplit: string[];
  gs2Split: string[];
  otCodes: string[][];
  ot2Codes: string[][];
  psCodes: string[][];
  ps2Codes: string[][];
  epCodes: string[][];
  ep2Codes: string[][];
  gsCodes: string[][];
  gs2Codes: string[][];
  startMonth: number;
  startDay: number;
  endMonth: number;
  endDay: number;
  priority: number;
  notes?: string;
  easter?: number;
  other?: string;
  date: string;
}

export interface VerseWithPericope extends Verse {
  pericopeRow: Pericope;
}

export interface Verse {
  bibleId: string;
  pericope: string;
  verseNumber: string;
  book: number;
  chapter: number;
  verse: number;
  paragraph: string;
  niv: string;
  esv: string;
  rsv: string;
  nrsv: string;
  asv: string;
  nasb: string;
  kjv: string;
  nkjv: string;
  ampc: string;
  nivNote?: string;
}

interface StudyToolsWordsCompactDefinition {
  id: number;
  definition: string;
}

interface StudyToolsWordsCompactWord {
  id: number;
  word: string;
  definitionId: number;
  inSermon: number;
  inScripture: number;
  inTags: number;
  see?: string;
  definition: StudyToolsWordsCompactDefinition;
}

export interface StudyToolsWordsCompact {
  id: number;
  studyToolsId: number;
  wordId: number;
  word: StudyToolsWordsCompactWord;
}

export interface NewDictionaryWord {
  id: number;
  word: string;
  definition: string;
  definitionTruncated: string | null;
  showWhenInSermon: number;
  showWhenInScripture: number;
  showOnReferencedPericopes: number;
  alias?: string;
}

export interface NewDictionaryWordsPericope {
  id: number;
  wordId: number;
  pericopeId: number;
  word: NewDictionaryWord;
}

export interface PericopeWithVerses extends Pericope {
  verses: Verse[];
}

export interface Pericope {
  bibleId: number;
  position: number;
  number: string;
  heading: string;
  book: BibleBook;
  chapter: number;
  bookChapter: string;
  fullReference: string;
  pericope?: string;
  abbrev: string;
  shortHeading: string;
}

export interface StudyTools {
  id: number;
  bibleId: number;
  handbookId: string;
  ovcId: string;
  handbook?: Content;
  ovc?: Content;
  commentary?: Content;
  bible: Pericope;
  scripture?: Verse[];
  directMatchesCompact: StudyToolsWordsCompact[];
  secondaryMatchesCompact: StudyToolsWordsCompact[];
  directMatchesExhaustive: NewDictionaryWordsPericope[];
  secondaryMatchesExhaustive: NewDictionaryWordsPericope[];
}

interface RssFeedItem {
  title: string;
  link: string;
  pubDate: string;
  content: string;
  guid: string;
  isoDate: string;
}

export interface RssFeed {
  items: RssFeedItem[];
  feedUrl: string;
  paginationLinks: {
    self: string;
  };
  title: string;
  description: string;
  link: string;
  language: string;
  copyright: string;
}

export interface Rss {
  christianpost?: RssFeed;
  christianitytoday?: RssFeed;
  flipboard?: RssFeed;
  archaeology?: RssFeed;
  realclearreligion?: RssFeed;
}

export interface ThisWeek extends TextAndTools {
  announcements: [];
  seriesCategories: SeriesCategories[];
  trendingSocial?: TrendingSocial[];
  initialism: string;
  scripture: string;
  sermons: Content[];
  powerpoints: Content[];
  media: MediaContent[];
  rss: Rss;
  sunday: string;
}

export interface TextAndTools {
  studyTools: {
    ot: StudyTools;
    ps: StudyTools;
    ep: StudyTools;
    gs: StudyTools;
  };
}

export interface SoloTextAndTools {
  studyTools: StudyTools;
}

export interface BibleBooks {
  oldTestament: string[];
  newTestament: string[];
}

export interface Denomination {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export type Denominations = Denomination[];

export interface Session {
  access_token: string;
}

export interface UserPayload {
  exp: number;
  iat: number;
  id: number;
  sub: number;
}

export interface PericopeSuggestion {
  code: string;
  reference: string;
}

export interface TbTocType {
  tocTypeId: string;
  tocName: string;
  tocDesc?: string;
  userLevel: number;
  tocSearch: number;
  tocAbbrev: string;
  tocShowInList?: boolean;
  tocShowInListMobile?: boolean;
  genre: string;
}

export type TrendingSocialResponse = Record<string, TrendingSocial[]>;

export interface BookmarkedSermons {
  sermonIds: string[];
}

export interface HelpResponse {
  contentTitle: string;
  author: string;
  contentType: string;
  body: string;
  publicationTitle: string;
  publisher: string;
  yearPublished: string;
  displayedScripture: string;
  scriptureReference: string;
}

export interface NewHelpResponse {
  newHelp: HelpResponse;
}

export const SEARCH_TYPES = [
  'Sermon Prep',
  'Illustrations · Quotes · Humor',
  'Commentary',
  'Dictionary',
  'Lectionary',
  'Bible',
  'Media',
  "Children's Sermons",
  "Children's Bulletins",
  'Sermon Series',
  'Bulletin & Sermon Aids',
  'King Duncan',
  'Authors A-Z',
  'Dramas',
  'Eulogies',
] as const;
export const MY_SERMONS_SEARCH_TYPES = [
  'Create',
  'Sermons',
  'Illustrations · Quotes · Humor',
  'Eulogies',
  'Devotionals',
  'Articles',
  'Letters',
  'Bible Studies',
  'All Resources',
] as const;
export const MY_SERMONS_TOC_TYPES = [
  'Sermon',
  'Illustration',
  'Quote',
  'Humor',
  'Eulogy',
  'Devotional',
  'Article',
  'Letter',
  'Bible Study',
] as const;
export const SEARCH_SUB_TYPES = [
  'N/A',
  'Keyword',
  'Scripture',
  'Year A',
  'Year B',
  'Year C',
  'Show All',
] as const;
export type SEARCH_TYPE = (typeof SEARCH_TYPES)[number];
export type MY_SERMONS_SEARCH_TYPE = (typeof MY_SERMONS_SEARCH_TYPES)[number];
export type MY_SERMONS_TOC_TYPE = (typeof MY_SERMONS_TOC_TYPES)[number];
export type SEARCH_SUB_TYPE = (typeof SEARCH_SUB_TYPES)[number];

export const isSearchType = (str?: string | null): str is SEARCH_TYPE => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return SEARCH_TYPES.includes(str as any);
};
export const isMySermonsSearchType = (
  str?: string | null,
): str is MY_SERMONS_SEARCH_TYPE => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return MY_SERMONS_SEARCH_TYPES.includes(str as any);
};
export const isSearchSubType = (
  str?: string | null,
): str is SEARCH_SUB_TYPE => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return SEARCH_SUB_TYPES.includes(str as any);
};

export interface WeeksResponse {
  lectionaryWeeks: Week[];
}

export interface LectionaryWeeks {
  a: Week[];
  b: Week[];
  c: Week[];
}

export interface NewWeeksResponse {
  lectionaryWeeks: NewWeek[];
}

export interface NewLectionaryWeeks {
  a: NewWeek[];
  b: NewWeek[];
  c: NewWeek[];
}

export interface NewWeek {
  id: string;
  lectionary: string | null;
  year: string | null;
  priority: string | null;
  reading: keyof NewLectionaryWeeksByReading;
  season: string | null;
  serviceNameFull: string | null;
  serviceNameL: string | null;
  serviceNameM: string | null;
  serviceNameS: string | null;
  weekDay: string | null;
  notes: string | null;
  date: string | null;
  citationL: string | null;
  citationM: string | null;
  citationS: string | null;
  bibleCode: string | null;
  position: string | null;
  resources: string | null;
  actualDate: string;
}

export interface NewLectionaryWeeksByReading {
  Gospel: NewWeek;
  Epistle: NewWeek;
  'Old Testament': NewWeek;
  Psalm: NewWeek;
}

export type NewLectionaryWeeksByService = Record<
  string,
  NewLectionaryWeeksByReading | undefined
>;

export const LECTIONARY_TOOLS_TABS = [
  'Prep',
  'Bulletin Aids',
  'Sermon Aids',
  'Illustrations',
  'Resources',
] as const;
export type LECTIONARY_TOOLS_TAB = (typeof LECTIONARY_TOOLS_TABS)[number];

export const LECTIONARY_YEARS_TABS = ['Year A', 'Year B', 'Year C'] as const;
export type LECTIONARY_YEARS_TAB = (typeof LECTIONARY_YEARS_TABS)[number];

export const LECTIONARY_TYPES_TABS = [
  'Revised Common Lectionary',
  'Roman Catholic Lectionary',
] as const;
export type LECTIONARY_TYPES_TAB = (typeof LECTIONARY_TYPES_TABS)[number];

export const isLectionaryType = (
  lectionary?: string | null,
): lectionary is LECTIONARY_TYPES_TAB => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return LECTIONARY_TYPES_TABS.includes(lectionary as any);
};

export const LECTIONARY_TYPES_MAP = {
  'Revised Common Lectionary': 'R',
  'Roman Catholic Lectionary': 'C',
} as const;

export type NotificationType = 'error' | 'success';
export interface Notification {
  type: NotificationType;
  message: string | string[];
  uuid?: string;
}

export interface LexicalEntry {
  root: string;
  synonyms: string[];
  antonyms: string[];
  count?: number;
}

export interface SynonymsAntonymsResponse {
  words: LexicalEntry[];
  totalWords: number;
  lastPage: number;
}

export type EditedWords = Record<
  string,
  {
    synonyms: string[];
    antonyms: string[];
  }
>;

export const SYN_ANT_SORT_OPTIONS = [
  'A-Z',
  'Recently Updated',
  'Most Popular',
] as const;
export type SYN_ANT_SORT_OPTION = (typeof SYN_ANT_SORT_OPTIONS)[number];

export const USER_SORT_OPTIONS = [
  'A-Z',
  'Signup Date',
  'Last Manuel Renewal',
  'Sign Up Bounces',
] as const;
export type USER_SORT_OPTION = (typeof USER_SORT_OPTIONS)[number];

export type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export const MY_SERMONS_SIDEBARS = ['prep', 'lectionary', 'none'] as const;
export type MY_SERMONS_SIDEBAR = (typeof MY_SERMONS_SIDEBARS)[number];

export interface ChaptersResponse {
  chapters: number[];
}

export interface VerseString {
  abbrev: string;
  code: string;
}

export interface VerseStringsResponse {
  verseStrings: VerseString[];
}

export type SuggestionType = 'keyword' | 'author' | 'scripture' | 'lectionary';

export interface Suggestion {
  term: string;
  override?: string;
  type: SuggestionType;
  currentCycle?: boolean;
}

export interface BroadcastEvent<T extends Serializable> {
  topic: string;
  data?: T;
}

export type Serializable =
  | string
  | number
  | boolean
  | null
  | Serializable[]
  | { [key: string]: Serializable };
